const Layer = resolve => require(['@/views/layer/layer.js'], resolve);

//概况
const General = resolve => require(['@/views/shop/general'], resolve);
//任务
const Task = resolve => require(['@/views/shop/task'], resolve);
//任务新增
const AddTask = resolve => require(['@/views/shop/taskAdd'], resolve);
//任务编辑
const EditTask = resolve => require(['@/views/shop/taskEdit'], resolve);
//任务详情
const DetailTask = resolve => require(['@/views/shop/taskDetail'], resolve);
//广告
const Advertisement = resolve => require(['@/views/shop/advertisement'], resolve);

//客户列表Layer
const CustomerListLayer = resolve => require(['@/views/shop/customerListLayer'], resolve);
//客户列表
const CustomerList = resolve => require(['@/views/shop/customerList'], resolve);
//添加客户
const CustomerAdd = resolve => require(['@/views/shop/customerAdd'], resolve);
//客户详情
const CustomerDetail = resolve => require(['@/views/shop/customerDetail'], resolve);
//客户管理
const CustomerEdit = resolve => require(['@/views/shop/customerEdit'], resolve);
//修改密码
const CustomerPwd = resolve => require(['@/views/shop/customerPwd'], resolve);
//已禁用客户管理
const CustomerForbidEdit = resolve => require(['@/views/shop/customerForbidEdit'], resolve);
//已禁用账号
const CustomerForbid = resolve => require(['@/views/shop/customerForbid'], resolve);

//店铺反馈Layer
const ShopFeedbackLayer = resolve => require(['@/views/shop/shopFeedbackLayer'], resolve);
//店铺反馈全部
const ShopFeedbackAll = resolve => require(['@/views/shop/shopFeedbackAll'], resolve);
//店铺反馈待处理
const ShopFeedbackPending = resolve => require(['@/views/shop/shopFeedbackPending'], resolve);
//店铺反馈已处理
const ShopFeedbackProcessed = resolve => require(['@/views/shop/shopFeedbackProcessed'], resolve);
//充电桩反馈Layer
const ChargingPileFeedbackLayer = resolve => require(['@/views/shop/chargingPileFeedbackLayer'], resolve);
//充电桩反馈全部
const ChargingPileFeedbackAll = resolve => require(['@/views/shop/chargingPileFeedbackAll'], resolve);
//充电桩反馈待处理
const ChargingPileFeedbackPending = resolve => require(['@/views/shop/chargingPileFeedbackPending'], resolve);
//充电桩反馈已处理
const ChargingPileFeedbackProcessed = resolve => require(['@/views/shop/chargingPileFeedbackProcessed'], resolve);
//车辆反馈Layer
const VehicleFeedbackLayer = resolve => require(['@/views/shop/vehicleFeedbackLayer'], resolve);
//车辆反馈全部
const VehicleFeedbackAll = resolve => require(['@/views/shop/vehicleFeedbackAll'], resolve);
//车辆反馈待处理
const VehicleFeedbackPending = resolve => require(['@/views/shop/vehicleFeedbackPending'], resolve);
//车辆反馈已处理
const VehicleFeedbackProcessed = resolve => require(['@/views/shop/vehicleFeedbackProcessed'], resolve);
//店铺管理Layer
const ShopManagementLayer = resolve => require(['@/views/shop/shopManagementLayer'], resolve);
//店铺管理列表
const ShopManagementList = resolve => require(['@/views/shop/shopManagementList'], resolve);
//店铺管理禁用列表
const ShopManagementDisabledList = resolve => require(['@/views/shop/shopManagementDisabledList'], resolve);
//充电桩管理Layer
const ChargingPileManagementLayer = resolve => require(['@/views/shop/chargingPileManagementLayer'], resolve);
//充电桩管理列表
const ChargingPileManagementList = resolve => require(['@/views/shop/chargingPileManagementList'], resolve);
//充电桩管理禁用列表
const ChargingPileManagementDisabledList = resolve => require(['@/views/shop/chargingPileManagementDisabledList'], resolve);
//车辆管理Layer
const VehicleManagementLayer = resolve => require(['@/views/shop/vehicleManagementLayer'], resolve);
//车辆管理列表
const VehicleManagementList = resolve => require(['@/views/shop/vehicleManagementList'], resolve);
//车辆管理禁用列表
const VehicleManagementDisabledList = resolve => require(['@/views/shop/vehicleManagementDisabledList'], resolve);

//服务管理
const ServiceManage = resolve => require(['@/views/shop/serviceManage'], resolve);
//新增服务
const ServiceAdd = resolve => require(['@/views/shop/serviceAdd'], resolve);
//编辑服务
const ServiceEdit = resolve => require(['@/views/shop/serviceEdit'], resolve);
//服务详情
const ServiceMessage = resolve => require(['@/views/shop/serviceMessage'], resolve);
//充电桩审核
const ServiceCheck = resolve => require(['@/views/shop/serviceCheck'], resolve);
//充电桩审核
const ShopCheck = resolve => require(['@/views/shop/shopCheck'], resolve);
//充电桩审核
const CarCheck = resolve => require(['@/views/shop/carCheck'], resolve);
//充电桩审核详情
const ServiceCheckDetail = resolve => require(['@/views/shop/serviceCheckDetail'], resolve);
//充电桩审核详情
const ShopCheckDetail = resolve => require(['@/views/shop/shopCheckDetail'], resolve);
//充电桩审核详情
const CarCheckDetail = resolve => require(['@/views/shop/carCheckDetail'], resolve);

// 商店反馈详情
const ShopFeedbackDetail = resolve => require(['@/views/shop/shopFeedbackDetail'], resolve);
// 充电桩反馈详情
const ChargingPileFeedbackDetail = resolve => require(['@/views/shop/chargingPileFeedbackDetail'], resolve);
// 车辆反馈详情
const VehicleFeedbackDetail = resolve => require(['@/views/shop/vehicleFeedbackDetail'], resolve);
// 商店管理详情
const ShopManagementDetail = resolve => require(['@/views/shop/shopManagementDetail'], resolve);
// 充电桩管理详情
const ChargingPileManagementDetail = resolve => require(['@/views/shop/chargingPileManagementDetail'], resolve);
// 车辆管理详情
const VehicleManagementDetail = resolve => require(['@/views/shop/vehicleManagementDetail'], resolve);


//项目管理
const ProjectManage = resolve => require(['@/views/shop/projectManage'], resolve);
//新增项目
const ProjectAdd = resolve => require(['@/views/shop/projectAdd'], resolve);
//编辑项目
const ProjectEdit = resolve => require(['@/views/shop/projectEdit'], resolve);
//项目详情
const ProjectDetail = resolve => require(['@/views/shop/projectDetail'], resolve);
//类目管理
const CategoryManage = resolve => require(['@/views/shop/categoryManage'], resolve);
//新增类目
const CategoryAdd = resolve => require(['@/views/shop/categoryAdd'], resolve);
//编辑类目
const CategoryEdit = resolve => require(['@/views/shop/categoryEdit'], resolve);
//类目详情
const CategoryDetail = resolve => require(['@/views/shop/categoryDetail'], resolve);

const MyInfo = resolve => require(['@/views/shop/myInfo'], resolve);

// 推广员管理
const SalesList = resolve => require(['@/views/shop/salesList'], resolve);
//  添加员工
const SalesAdd = resolve => require(['@/views/shop/salesAdd'], resolve);
// 推广员详情
const SalesDetail = resolve => require(['@/views/shop/salesDetail'], resolve);
//  推广员管理
const SalesEdit = resolve => require(['@/views/shop/salesEdit'], resolve);



//推广员控制台容器
const PromoterConsoleLayer = resolve => require(['@/views/shop/promoterConsoleLayer'], resolve);
//推广员控制台
const PromoterConsole = resolve => require(['@/views/shop/promoterConsole'], resolve);
//推广员客户
const PromoterCustomer = resolve => require(['@/views/shop/promoterCustomer'], resolve);
//推广员推广额记录
const PromoterRecord = resolve => require(['@/views/shop/promoterRecord'], resolve);
//推广员客户详情
const PromoterCustomerDetail = resolve => require(['@/views/shop/customerDetail'], resolve);
//修改密码
const PromoterPwd = resolve => require(['@/views/shop/promoterPwd'], resolve);

//员工列表Layer
const StaffListLayer = resolve => require(['@/views/shop/staffListLayer'], resolve);
//员工列表
const StaffList = resolve => require(['@/views/shop/staffList'], resolve);
//添加员工
const StaffAdd = resolve => require(['@/views/shop/staffAdd'], resolve);
//员工详情
const StaffDetail = resolve => require(['@/views/shop/staffDetail'], resolve);
//员工管理  
const StaffEdit = resolve => require(['@/views/shop/staffEdit'], resolve);
//修改密码
const StaffPwd = resolve => require(['@/views/shop/staffPwd'], resolve);
//角色管理列表
const ActorList = resolve => require(['@/views/shop/actorList'], resolve);
//新增角色
const ActorAdd = resolve => require(['@/views/shop/actorAdd'], resolve);
//编辑角色
const ActorEdit = resolve => require(['@/views/shop/actorEdit'], resolve);
//角色详情
const ActorDetail = resolve => require(['@/views/shop/actorDetail'], resolve);
//已禁用账号
const StaffForbid = resolve => require(['@/views/shop/staffForbid'], resolve);

//订单列表
const OrderList = (resolve) => require(["@/views/shop/orderList"], resolve);
//订单详情
// const OrderDetail = (resolve) => require(["@/views/shop/orderDetail"], resolve);
//订单退款
// const OrderRefund = (resolve) => require(["@/views/shop/refund"], resolve);

//通用设置
const CommonSetting = (resolve) => require(["@/views/shop/commonSetting"], resolve);
//订单消耗点数配置
const ConsumeConfig = (resolve) => require(["@/views/shop/consumeConfig"], resolve);

/**
 * 多级子路由在Layer的children后面继续添加 不需要再在children中嵌套children
 */

export const shopRouter = [
  {
    path: '/general',
    meta: {
      title: '概况',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      id: 999,
      show: true
    },
    redirect: '/general/index',
    component: Layer,
    children: [{
      path: '/general/index',
      name: 'general',
      component: General,
      meta: {
        title: '概况',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/staff',
    meta: {
      title: '员工',
      icon: 'iconfont al-icon-yuangong',
      icon_m: 'iconfont al-icon-yuangong',
      id: 1000,
      show: true
    },
    redirect: '/staff/staffList',
    component: Layer,
    children: [{
      path: '/staff/staffList',
      name: 'staffListLayer',
      redirect: '/staff/staffList/staffList',
      component: StaffListLayer,
      meta: {
        title: '员工列表',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: true
      },
      children: [
        {
          path: '/staff/staffList/staffList',
          name: 'staffList',
          component: StaffList,
          meta: {
            title: '员工列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/staff/staffList/staffAdd',
          name: 'staffAdd',
          component: StaffAdd,
          meta: {
            title: '添加员工',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffDetail',
          name: 'staffDetail',
          component: StaffDetail,
          meta: {
            title: '员工列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffEdit',
          name: 'staffEdit',
          component: StaffEdit,
          meta: {
            title: '员工管理',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffPwd',
          name: 'staffPwd',
          component: StaffPwd,
          meta: {
            title: '修改密码',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList',
          name: 'actorList',
          component: ActorList,
          meta: {
            title: '角色管理列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorAdd',
          name: 'actorAdd',
          component: ActorAdd,
          meta: {
            title: '新增角色',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorEdit',
          name: 'actorEdit',
          component: ActorEdit,
          meta: {
            title: '编辑角色',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorDetail',
          name: 'actorDetail',
          component: ActorDetail,
          meta: {
            title: '角色详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/forbid',
          name: 'staffForbid',
          component: StaffForbid,
          meta: {
            title: '已禁用账号',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
    }]
  },
  {
    path: '/customer',
    meta: {
      title: '客户',
      icon: 'iconfont al-icon-yuangong',
      icon_m: 'iconfont al-icon-yuangong',
      show: true,
      id: 1001,
    },
    redirect: '/customer/customerList/customerList',
    component: Layer,
    children: [{
      path: '/customer/customerList',
      name: 'customerListLayer',
      component: CustomerListLayer,
      meta: {
        title: '客户列表',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      },
      children: [
        {
          path: '/customer/customerList/customerList',
          name: 'customerList',
          component: CustomerList,
          meta: {
            title: '客户列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/customer/customerList/customerList/customerAdd',
          name: 'customerAdd',
          component: CustomerAdd,
          meta: {
            title: '添加客户',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/customerList/customerDetail',
          name: 'customerListDetail',
          component: CustomerDetail,
          meta: {
            title: '客户详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/forbid/customerDetail',
          name: 'customerForbidDetail',
          component: CustomerDetail,
          meta: {
            title: '客户详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/customerList/customerEdit',
          name: 'customerEdit',
          component: CustomerEdit,
          meta: {
            title: '客户编辑',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/customerList/customerPwd',
          name: 'customerPwd',
          component: CustomerPwd,
          meta: {
            title: '修改密码',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/forbid/customerPwd',
          name: 'customerForbidPwd',
          component: CustomerPwd,
          meta: {
            title: '修改密码',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/customer/customerList/forbid',
          name: 'customerForbid',
          component: CustomerForbid,
          meta: {
            title: '已禁用账号',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/customer/customerList/forbid/edit',
          name: 'customerForbidEdit',
          component: CustomerForbidEdit,
          meta: {
            title: '已禁用账号编辑',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
    }
    ]
  },
  {
    path: "/order",
    meta: {
      title: "订单",
      icon: "iconfont al-icon-dingdan",
      icon_m: "iconfont al-icon-dingdan",
      id: 1009,
      show: true,
    },
    redirect: "/order/list",
    component: Layer,
    children: [
      {
        path: "/order/list",
        name: "orderList",
        component: OrderList,
        meta: {
          title: "订单列表",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          id: 1009,
          show: true,
          keepAlive: true,
        },
      },
      // {
      //   path: "/order/list/detail",
      //   name: "orderDetail",
      //   component: OrderDetail,
      //   meta: {
      //     title: "订单详情",
      //     icon: "el-icon-menu",
      //     icon_m: "md-apps",
      //     id: 1005001,
      //     show: false,
      //   },
      // },
      // {
      //   path: "/order/list/refund",
      //   name: "orderRefund",
      //   component: OrderRefund,
      //   meta: {
      //     title: "订单退款",
      //     icon: "el-icon-menu",
      //     icon_m: "md-apps",
      //     id: 1005001,
      //     show: false,
      //   },
      // },
    ],
  },
  {
    path: '/advertisement',
    meta: {
      title: '广告',
      icon: 'iconfont al-icon-neirongmoxingshezhi',
      icon_m: 'iconfont al-icon-neirongmoxingshezhi',
      id: 1005,
      show: true
    },
    redirect: '/advertisement/index',
    component: Layer,
    children: [{
      path: '/advertisement/index',
      name: 'advertisement',
      component: Advertisement,
      meta: {
        title: '广告',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },


  // {
  //   path: '/service',
  //   meta: {
  //     title: '审核',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true,
  //     id: 1002,
  //   },
  //   redirect: '/service/serviceManage',
  //   component: Layer,
  //   children: [{
  //     path: '/service/serviceManage/add',
  //     name: 'serviceAdd',
  //     component: ServiceAdd,
  //     meta: {
  //       title: '新增服务',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/service/serviceManage/edit',
  //     name: 'serviceEdit',
  //     component: ServiceEdit,
  //     meta: {
  //       title: '编辑服务',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/service/serviceManage/message',
  //     name: 'serviceMessage',
  //     component: ServiceMessage,
  //     meta: {
  //       title: '服务详情',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/service/serviceCheck',
  //     name: 'serviceCheck',
  //     component: ServiceCheck,
  //     meta: {
  //       title: '充电桩审核',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       id: 1002001,
  //       show: true
  //     }
  //   },
  //   {
  //     path: '/service/shopCheck',
  //     name: 'shopCheck',
  //     component: ShopCheck,
  //     meta: {
  //       title: '店铺审核',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       id: 1002002,
  //       show: true
  //     }
  //   },
  //   {
  //     path: '/service/carCheck',
  //     name: 'carCheck',
  //     component: CarCheck,
  //     meta: {
  //       title: '车辆审核',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       id: 1002003,
  //       show: true
  //     }
  //   }, {
  //     path: '/service/serviceCheck/Detail',
  //     name: 'serviceCheckDetail',
  //     component: ServiceCheckDetail,
  //     meta: {
  //       title: '审核详情',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/service/shopCheck/Detail',
  //     name: 'shopCheckDetail',
  //     component: ShopCheckDetail,
  //     meta: {
  //       title: '审核详情',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/service/carCheck/Detail',
  //     name: 'carCheckDetail',
  //     component: CarCheckDetail,
  //     meta: {
  //       title: '审核详情',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }
  //   ]
  // },
  // {
  //   path: '/shopFeedback',
  //   meta: {
  //     title: '反馈',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true,
  //     id: 1003
  //   },
  //   component: Layer,
  //   children: [
  //     {
  //       redirect: '/shopFeedback/shopFeedbackAll/shopFeedbackAll',
  //       path: '/shopFeedback/shopFeedbackAll',
  //       name: 'shopFeedbackAllLayer',
  //       component: ShopFeedbackLayer,
  //       meta: {
  //         title: '店铺反馈',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         show: true,
  //         id: 1003001
  //       },
  //       children: [
  //         {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackAll',
  //           name: 'shopFeedbackAll',
  //           component: ShopFeedbackAll,
  //           meta: {
  //             title: '店铺反馈列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackPending',
  //           name: 'shopFeedbackPending',
  //           component: ShopFeedbackPending,
  //           meta: {
  //             title: '待处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackProcessed',
  //           name: 'shopFeedbackProcessed',
  //           component: ShopFeedbackProcessed,
  //           meta: {
  //             title: '已处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackAll/detail',
  //           name: 'shopFeedbackDetail1',
  //           component: ShopFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackPending/detail',
  //           name: 'shopFeedbackDetail2',
  //           component: ShopFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/shopFeedback/shopFeedbackAll/shopFeedbackProcessed/detail',
  //           name: 'shopFeedbackDetail3',
  //           component: ShopFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     },
  //     {
  //       path: '/chargingPileFeedback',
  //       name: 'chargingPileFeedbackLayer',
  //       component: ChargingPileFeedbackLayer,
  //       redirect: '/chargingPileFeedback/chargingPileFeedbackAll',
  //       meta: {
  //         title: '充电桩反馈',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         show: true,
  //         id: 1003002
  //       },
  //       children: [
  //         {
  //           path: '/chargingPileFeedback/chargingPileFeedbackAll',
  //           name: 'chargingPileFeedbackAll',
  //           component: ChargingPileFeedbackAll,
  //           meta: {
  //             title: '充电桩反馈列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/chargingPileFeedback/chargingPileFeedbackPending',
  //           name: 'chargingPileFeedbackPending',
  //           component: ChargingPileFeedbackPending,
  //           meta: {
  //             title: '待处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/chargingPileFeedback/chargingPileFeedbackProcessed',
  //           name: 'chargingPileFeedbackProcessed',
  //           component: ChargingPileFeedbackProcessed,
  //           meta: {
  //             title: '已处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/chargingPileFeedback/chargingPileFeedbackAll/detail',
  //           name: 'chargingPileFeedbackDetail1',
  //           component: ChargingPileFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/chargingPileFeedback/chargingPileFeedbackPending/detail',
  //           name: 'chargingPileFeedbackDetail2',
  //           component: ChargingPileFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/chargingPileFeedback/chargingPileFeedbackProcessed/detail',
  //           name: 'chargingPileFeedbackDetail3',
  //           component: ChargingPileFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     },
  //     {
  //       path: '/vehicleFeedback',
  //       name: 'vehicleFeedbackLayer',
  //       component: VehicleFeedbackLayer,
  //       redirect: '/vehicleFeedback/vehicleFeedbackAll',
  //       meta: {
  //         title: '车辆反馈',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         show: true,
  //         id: 1003003
  //       },
  //       children: [
  //         {
  //           path: '/vehicleFeedback/vehicleFeedbackAll',
  //           name: 'vehicleFeedbackAll',
  //           component: VehicleFeedbackAll,
  //           meta: {
  //             title: '车辆反馈列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/vehicleFeedback/vehicleFeedbackPending',
  //           name: 'vehicleFeedbackPending',
  //           component: VehicleFeedbackPending,
  //           meta: {
  //             title: '待处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/vehicleFeedback/vehicleFeedbackProcessed',
  //           name: 'vehicleFeedbackProcessed',
  //           component: VehicleFeedbackProcessed,
  //           meta: {
  //             title: '已处理反馈',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/vehicleFeedback/vehicleFeedbackAll/detail',
  //           name: 'vehicleFeedbackDetail1',
  //           component: VehicleFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/vehicleFeedback/vehicleFeedbackPending/detail',
  //           name: 'vehicleFeedbackDetail2',
  //           component: VehicleFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/vehicleFeedback/vehicleFeedbackProcessed/detail',
  //           name: 'vehicleFeedbackDetail3',
  //           component: VehicleFeedbackDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     }
  //   ]
  // },
  // {
  //   path: '/shopManagement',
  //   meta: {
  //     title: '管理',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true,
  //     id: 1004
  //   },
  //   component: Layer,
  //   children: [
  //     {
  //       redirect: '/shopManagement/shopManagementList/shopManagementList',
  //       path: '/shopManagement/shopManagementList',
  //       name: 'shopManagementListLayer',
  //       component: ShopManagementLayer,
  //       meta: {
  //         title: '店铺管理',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         id: 1004001,
  //         show: true
  //       },
  //       children: [
  //         {
  //           path: '/shopManagement/shopManagementList/shopManagementList',
  //           name: 'shopManagementList',
  //           component: ShopManagementList,
  //           meta: {
  //             title: '店铺列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/shopManagement/shopManagementList/disabled',
  //           name: 'shopManagementDisabled',
  //           component: ShopManagementDisabledList,
  //           meta: {
  //             title: '已禁用账号',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/shopManagement/shopManagementList/shopManagementList/detail',
  //           name: 'shopManagementDetail1',
  //           component: ShopManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/shopManagement/shopManagementList/disabled/detail',
  //           name: 'shopManagementDetail2',
  //           component: ShopManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     },
  //     {
  //       redirect: '/chargingPileManagement/chargingPileManagementList',
  //       path: '/chargingPileManagement',
  //       name: 'chargingPileManagementLayer',
  //       component: ChargingPileManagementLayer,
  //       meta: {
  //         title: '充电桩管理',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         id: 1004002,
  //         show: true
  //       },
  //       children: [
  //         {
  //           path: '/chargingPileManagement/chargingPileManagementList',
  //           name: 'chargingPileManagementList',
  //           component: ChargingPileManagementList,
  //           meta: {
  //             title: '充电桩列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/chargingPileManagement/chargingPileManagementDisabledList',
  //           name: 'chargingPileManagementDisabledList',
  //           component: ChargingPileManagementDisabledList,
  //           meta: {
  //             title: '已禁用账号',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/chargingPileManagement/chargingPileManagementList/detail',
  //           name: 'chargingPileManagementDetail1',
  //           component: ChargingPileManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/chargingPileManagement/chargingPileManagementDisabledList/detail',
  //           name: 'chargingPileManagementDetail2',
  //           component: ChargingPileManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     },
  //     {
  //       redirect: '/vehicleManagement/vehicleManagementList',
  //       path: '/vehicleManagement',
  //       name: 'vehicleManagementLayer',
  //       component: VehicleManagementLayer,
  //       meta: {
  //         title: '车辆管理',
  //         icon: 'el-icon-menu',
  //         icon_m: 'md-apps',
  //         id: 1004003,
  //         show: true
  //       },
  //       children: [
  //         {
  //           path: '/vehicleManagement/vehicleManagementList',
  //           name: 'vehicleManagementList',
  //           component: VehicleManagementList,
  //           meta: {
  //             title: '车辆列表',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         },
  //         {
  //           path: '/vehicleManagement/vehicleManagementDisabledList',
  //           name: 'vehicleManagementDisabledList',
  //           component: VehicleManagementDisabledList,
  //           meta: {
  //             title: '已禁用账号',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/vehicleManagement/vehicleManagementList/detail',
  //           name: 'vehicleManagementDetail1',
  //           component: VehicleManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }, {
  //           path: '/vehicleManagement/vehicleManagementDisabledList/detail',
  //           name: 'vehicleManagementDetail2',
  //           component: VehicleManagementDetail,
  //           meta: {
  //             title: '详情',
  //             icon: 'el-icon-menu',
  //             icon_m: 'md-apps',
  //             show: false
  //           }
  //         }]
  //     }
  //   ]
  // },
  // {
  //   path: '/advertisement',
  //   meta: {
  //     title: '广告',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     id: 1005,
  //     show: true
  //   },
  //   redirect: '/advertisement/index',
  //   component: Layer,
  //   children: [{
  //     path: '/advertisement/index',
  //     name: 'advertisement',
  //     component: Advertisement,
  //     meta: {
  //       title: '广告',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }]
  // },
  // // 推广
  // {
  //   path: '/distribution',
  //   meta: {
  //     title: '推广',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     id: 1006,
  //     show: true
  //   },
  //   redirect: '/distribution/salesList',
  //   component: Layer,
  //   children: [{
  //     path: '/distribution/salesList',
  //     name: 'salesList',
  //     component: SalesList,
  //     meta: {
  //       title: '推广员管理',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       id: 1006001,
  //       show: true
  //     }
  //   }, {
  //     path: '/distribution/salesList/salesAdd',
  //     name: 'salesAdd',
  //     component: SalesAdd,
  //     meta: {
  //       title: '添加推广员',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   },
  //   {
  //     path: '/distribution/salesList/salesDetail',
  //     name: 'salesDetail',
  //     component: SalesDetail,
  //     meta: {
  //       title: '推广员详情',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   },
  //   {
  //     path: '/distribution/salesList/staffPwd',
  //     name: 'promoterPwd',
  //     component: PromoterPwd,
  //     meta: {
  //       title: '修改密码',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }
  //   ]
  // },

  {
    path: "/setting",
    meta: {
      title: "设置",
      icon: "iconfont al-icon-shezhi",
      icon_m: "iconfont al-icon-shezhi",
      id: 1010,
      show: true,
    },
    redirect: "/setting/common",
    component: Layer,
    children: [
      {
        path: "/setting/common",
        name: "commonSetting",
        component: CommonSetting,
        meta: {
          title: "通用设置",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          id: 1010001,
          show: true,
        },
      },
      {
        path: "/setting/consumeConfig",
        name: "consumeConfig",
        component: ConsumeConfig,
        meta: {
          title: "订单消耗点数配置",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          id: 1010002,
          show: true,
        },
      },
    ],
  },
  {
    path: '/myInfo',
    meta: {
      title: '个人信息',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      show: false
    },
    redirect: '/myInfo/index',
    component: Layer,
    children: [{
      path: '/myInfo/index',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: '个人信息',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/test',
    meta: {
      title: '个人信息',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      show: false
    },
    redirect: '/test/index',
    component: Layer,
    children: [{
      path: '/test/index/:id',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: '个人信息',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
]

export const promoterRouter = [
  {
    path: '/promoter',
    meta: {
      title: '推广员',
      icon: 'iconfont al-icon-tuiguang',
      icon_m: 'iconfont al-icon-tuiguang',
      id: 100700001,
      show: true
    },
    redirect: '/promoter/console',
    component: Layer,
    children: [
      {
        path: '/promoter/console',
        name: 'promoterConsoleLayer',
        redirect: '/promoter/console/index',
        component: PromoterConsoleLayer,
        meta: {
          title: '推广员控制台',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 100700001,
          show: true
        },
        children: [{
          path: '/promoter/console/index',
          name: 'promoterConsole',
          component: PromoterConsole,
          meta: {
            title: '推广员控制台',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/customer',
          name: 'promoterCustomer',
          component: PromoterCustomer,
          meta: {
            title: '推广员客户',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/customer/detail',
          name: 'promoterCustomerDetail',
          component: PromoterCustomerDetail,
          meta: {
            title: '推广员客户详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/record',
          name: 'promoterRecord',
          component: PromoterRecord,
          meta: {
            title: '推广收益明细',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
      }]
  },
  {
    path: '/myInfo',
    meta: {
      title: '个人信息',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      show: false
    },
    redirect: '/myInfo/index',
    component: Layer,
    children: [{
      path: '/myInfo/index',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: '个人信息',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  }
]
