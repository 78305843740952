import router, {
  resetRouter,
  shopRoutes,
  promoterRoutes,
  commonRoutes
} from '@/router/routerMain'
import $x from '@/libs/$x'

const Layer = resolve => require(['@/views/layer/layer.js'], resolve);
//店铺列表
const ShopList = resolve => require(['@/views/shop/shopList'], resolve);
//创建编辑店铺
const CreateShop = resolve => require(['@/views/shop/createShop'], resolve);

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  setRoutes: (state, routes) => {
    state.addRoutes = routes
    state.routes = commonRoutes.concat(routes)
  }
}

const getters = {
  navMenu: (state) => {
    if (state.addRoutes) {
      return state.addRoutes
    }
    return []
  }
}

const handleManageRouter = (oldRouter1, oldRouter2, resData, originData) => {
  if (originData.userType === 3) {
    return oldRouter2
  }

  const allRouter = oldRouter1.concat(oldRouter2)
  const idLists = resData.map(item => item.id)

  //将allRouter中children 及children中的children 的show设为false
  const handleShow = (router) => {
    router.forEach(item => {
      if (item.children) {
        handleShow(item.children)
      }
      item.meta.show = false
    })
  }
  handleShow(allRouter)

  //将allRouter中的idLists中的id对应的路由的show设为true
  const handleShow2 = (router) => {
    router.forEach(item => {
      if (idLists.includes(item.meta.id)) {
        item.meta.show = true
      }
      if (item.children) {
        handleShow2(item.children)
      }
    })
  }
  handleShow2(allRouter)

  return allRouter
}

const fixedRedirectRouter = (routerList)=>{
  routerList.forEach(item=>{
    if(item.redirect && item.children && item.children.length > 0){
      item.redirect = item.children[0].path
    }
  })
}

const actions = {
  //addRoutes若有 则设置路由为当前栈中首个路由
  toDefaultRouter({
    state,
    commit,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      console.log(state.addRoutes)
      if (state.addRoutes && state.addRoutes.length > 0) {
        //如果首个路由为店铺列表 则直接进入店铺列表
        if (state.addRoutes[0].path === '/shopList') {
          router.push(state.addRoutes[0].path)
        } else {
          state.addRoutes.some(item => {
            if (item.meta.show) {
              if (item.children) {
                let subTag = item.children.some(inner => {
                  if (inner.meta.show) {
                    router.push(inner.path)
                    return true
                  }
                })
                if (!subTag) {
                  router.push(item.path)
                }
              } else {
                router.push(item.path)
              }
              return true
            }
            return false
          })
        }
        resolve()
      }
    })
  },
  //初始化路由
  initRouter({
    commit,
    dispatch
  }, userInfo) {
    return new Promise(async (resolve, reject) => {
      resetRouter()
      let accessedRoutes = []
      //需另外调取接口获取实际的menu
      const {
        data,
        code,
        message
      } = await $x.get('/public/admin/auth-manage/getUserInfoByToken')
      if (code === 200) {
        const newRoutes = handleManageRouter(shopRoutes, promoterRoutes, data['permissionsList'], data) || []
        console.log(newRoutes)

        fixedRedirectRouter(newRoutes)
        
        commit('setRoutes', newRoutes)
        router.addRoutes(newRoutes)
        // if(data.userType === 2){
        //   accessedRoutes = shopRoutes
        //   console.log(accessedRoutes)
        //   commit('setRoutes', accessedRoutes)
        //   router.addRoutes(accessedRoutes)
        // }else{
        //   //推广员
        //   accessedRoutes = promoterRoutes
        //   console.log(accessedRoutes)
        //   commit('setRoutes', accessedRoutes)
        //   router.addRoutes(accessedRoutes)
        // }
      } else {
        reject(message)
      }
      // accessedRoutes = shopRoutes
      // commit('setRoutes', accessedRoutes)
      // router.addRoutes(accessedRoutes)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
