import $x from '@/libs/$x'

export default {
  namespaced: true,
  state: {
    shopId: null,
    merchantStatus: 0,
    userInfo: {},
    userPermission: [],
    shops: [],
    notice: {}
  },
  mutations: {
    setUserInfo: (state, data) => {
      state.userInfo = data
      state.shopId = data.shopId
      state.userPermission = data.permissionsList
    },
  },
  getters: {
    role: (state) => {
      if (state.userInfo) {
        return state.userInfo.position
      }
      return ''
    }
  },
  actions: {
    async updateUserInfo({
      commit,
      dispatch,
      getters
    }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem("USER_INFO")
        if (!userInfo) {
          resolve()
          return
        }
        const {
          data,
          code,
          message
        } = await $x.get('/public/admin/auth-manage/getUserInfoByToken')
        if (code === 200) {
          if (data) {
            commit('setUserInfo', data)
            resolve()
          }
        } else {
          reject(message)
        }
      })
    },
    //刷新token
    refreshToken({
      commit,
      dispatch,
      getters
    }) {
      return new Promise(async (resolve, reject) => {
        const userInfo = $x.localStorage.getItem('USER_INFO')
        if (userInfo) {
          const reTime = userInfo['expireTime']
          if (new Date().getTime() > reTime) {
            $x.localStorage.removeItem('USER_INFO')
            resolve()
            return
          }
          if (new Date().getTime() + 3600000 > reTime) {
            const refreshToken = userInfo['refreshToken']
            const response = await $x.post('/public/refreshtoken', {
              params: {
                refreshToken
              }
            })
            if (response && response['token']) {
              const saveInfo = response
              saveInfo['expireTime'] = new Date().getTime() + saveInfo['expiryDuration']
              $x.localStorage.setItem('USER_INFO', saveInfo)
              resolve()
              return
            }
            resolve()
          }
        }
      });
    },
    //登录
    handleLogin({
      commit,
      dispatch,
      getters
    }, {
      userAccount,
      password
    }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await $x.post('/public/admin/auth-manage/login', {
            params: {
              account:userAccount,
              pwd:password,
              code:'',
              key:''
            }
          })
          if (response.data) {
            sessionStorage.clear();
            const userInfo = response.data
            userInfo['expireTime'] = new Date().getTime() + userInfo['expiryDuration'] || 0
            $x.localStorage.setItem('USER_INFO', userInfo)
            //重置初始化路由
            await dispatch("updateUserInfo")
            await dispatch('routes/initRouter', userInfo, {
              root: true
            }).catch(err => {
              reject(err)
            })
            //跳转至默认路由
            await dispatch('routes/toDefaultRouter', null, {
              root: true
            })
            resolve()
          } else {
            reject(response.message)
          }
        } catch (e) {
          console.log(e)
          reject('登录失败！')
        }
      });
    },
    //注册
    handleRegister({
      commit,
      dispatch,
      getters
    }, {
      name,
      password,
      rePassword,
      userAccount
    }) {
      return new Promise(async (resolve, reject) => {
        try {
          const {
            success,
            message
          } = await $x.post('/public/signup', {
            params: {
              name,
              password,
              userAccount
            }
          })
          console.log(success, message)
          if (success === true) {
            resolve()
          } else {
            reject(message)
          }
        } catch (err) {
          if (err.response.status === 400) {
            reject(err.response.data.message)
          } else if (!err.response.data.success) {
            reject(err.response.data.message)
          }
        }
      });
    }
  }
}
